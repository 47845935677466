<template>
  <v-card class="ma-3">
    <base-loading v-if="loading" />
    <crud-list
      v-else
      v-model="centrals"
      :headers="headers"
      :slots="['item.Celular']"
      :actions="actions"
    >
      <template v-if="$user.get().role === 'admin'" v-slot:btnCadastro>
        <v-row>
          <v-col>
            <v-btn
              color="green darken-1"
              dark
              class="rounded-lg"
              :loading="loadingSheet"
              @click="exportSheet"
            >
              <v-icon class="mr-2">mdi-file-excel</v-icon>

              <span style="font-size: 16px; font-weight: 600">Exportar</span>
            </v-btn>
          </v-col>
          <v-col>
            <router-link :to="`/${$user.get().role}/central/form`">
              <v-btn color="success"> Novo Supervisor </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.Celular`]="{ item }">
        {{ item.Celular ? celular(item.Celular) : ' - ' }}
      </template>
    </crud-list>
  </v-card>
</template>

<script>
import { celular } from '../../utils/masks'
import { JsonToExcel } from '../../utils/Worksheet'

export default {
  data() {
    return {
      actions: [
        {
          title: 'Editar Supervisor',
          color: 'yellow darken-3',
          click: item =>
            this.$router.push({
              path: 'central/form',
              query: {
                id: item.id,
              },
            }),
          icon: 'mdi-pencil',
        },
        {
          title: 'Deletar gestor',
          color: 'red darken-3',
          click: item => this.deleteItem(item),
          icon: 'mdi-delete',
        },
        {
          title: 'Associação',
          color: 'blue darken-3',
          click: item => this.association(item),
          icon: 'mdi-account-multiple-plus',
        },
      ],
      centrals: [],
      loading: true,
      loadingSheet: false,
      headers: [
        this.$user.get().role !== 'gestor' && {
          text: '',
          sortable: false,
          width: '80px',
          value: 'actions',
          align: 'left',
        },
        {
          text: 'Nome',
          align: 'left',
          sortable: true,
          value: 'Nome',
          width: 'auto',
          filterable: true,
        },
        {
          text: 'Email',
          align: 'left',
          sortable: true,
          value: 'Email',
          width: 'auto',
          filterable: true,
        },
        {
          text: 'Telefone',
          align: 'left',
          sortable: true,
          value: 'Celular',
          width: 'auto',
        },
      ],
    }
  },
  created() {
    this.getCentral()
  },
  methods: {
    celular,
    getCentral() {
      this.api.get
        .centrals()
        .then(data => {
          this.centrals = data
          this.loading = false
        })
        .catch(err => {
          this.Toast().fire({
            icon: 'error',
            title: `Erro ao buscar os supervisores: ${err?.message}`,
          })
        })
    },

    exportSheet() {
      this.loadingSheet = true

      new JsonToExcel(
        this.centrals,
        this.headers,
        'supervisores.xlsx',
        'Supervisores',
      )

      this.loadingSheet = false
    },

    deleteItem(item) {
      this.Swal.fire({
        icon: 'error',
        title: 'Deletar Supervisor',
        text: 'Você tem certeza que deseja deletar este supervisor?',
        showCancelButton: true,
        confirmButtonColor: '#f74242',
        confirmButtonText: 'SIM, DELETAR!',
        cancelButtonText: 'NÃO',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.api.delete.central(item.id).then(
            () => {
              return 1
            },
            reject => {
              return reject
            },
          )
        },
        allowOutsideClick: () => !this.Swal.isLoading(),
      })
        .then(result => {
          if (result.value === 1) {
            this.loading = true
            this.getCentral()
            this.Swal.fire({
              title: 'Sucesso',
              text: 'O gestor foi inativado com sucesso!',
              icon: 'success',
              showConfirmButton: false,
              timer: 2000,
            })
          }
        })
        .catch(err => {
          this.Swal.fire('Erro!', err, 'error')
        })
    },

    association(item) {
      this.$router.push({
        path: 'central/association',
        query: {
          id: item.id,
        },
      })
    },
  },
}
</script>
